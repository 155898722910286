import { useContext } from 'react';
import { compose } from 'recompose';
import { Box, Divider, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { NavLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import TextLogo from '@uptime/shared/components/TextLogo';
import PublicIcon from '@mui/icons-material/Public';

import AuthContext from 'context/AuthContext';
import { getImageUrl } from '@uptime/shared/utils/general';
import UserAvatar from '@uptime/shared/components/UserAvatar';
import { HOME_PATH } from 'shared/constants/urls';

import DW from 'assets/logos/DW.png';
import { useStyles } from './LeftNav.styles';
import MenuItems from './partials/MenuItems';
import HelpMenuItem from './partials/HelpMenuItem';

const LeftNav = (props) => {
  const {
    clicked,
    match,
    user,
    image: { url, fetching },
    whiteLabel,
    businessStep,
  } = props;
  const classes = useStyles();

  const stepNumber = user?.step;
  const hideBusinessTabs = stepNumber <= businessStep;

  const { accountId, isTopLevelFacility, isRepairer } = useContext(AuthContext);
  const companySiteUrl = user?.companySiteUrl ?? null;

  const businessName = user && user.businessName;
  const avatarSrc = getImageUrl(user && user.image);

  const makeUrl = (url) => {
    return `${match.url}${import.meta.env.VITE_PREFIX}/${url}`;
  };

  return (
    <div className={classes.sidebar}>
      <Box>
        <Box className={classes.toolbar} textAlign="center">
          <Box mt={2} mb={1}>
            {whiteLabel ? (
              <Box component="a" target="_blank" href={whiteLabel.site}>
                <img src={whiteLabel.logoUrl} alt="Partner logo" style={{ maxWidth: '80%' }} />
              </Box>
            ) : (
              <NavLink to={isRepairer ? '/app/work-orders' : HOME_PATH}>
                {accountId === 5780 ? (
                  <img src={DW} alt="DentalWhale logo" style={{ maxWidth: '80%' }} />
                ) : (
                  <TextLogo />
                )}
              </NavLink>
            )}
          </Box>
        </Box>
        <Divider />
        <Box sx={{ height: '72px' }} pl={2} display="flex" alignItems="center">
          <UserAvatar
            name={businessName}
            isLoading={fetching}
            src={url || avatarSrc}
            variant="rounded"
            className={classes.companyLogo}
          />
          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" ml={1}>
            <Box sx={{ fontSize: '16px', fontWeight: '700', lineHeight: '24px' }}>{businessName}</Box>
          </Box>
        </Box>
        <List sx={{ py: 0 }}>
          <Box sx={{ padding: '0 20px 0 20px' }}>
            <Divider />
          </Box>
          <MenuItems
            makeUrl={makeUrl}
            onClickItem={clicked}
            isTopLevelFacility={isTopLevelFacility}
            isRepairer={isRepairer}
            hideBusinessTabs={hideBusinessTabs}
          />
          {!isRepairer && Boolean(companySiteUrl) && (
            <ListItem component="a" href={companySiteUrl?.url} target="_blank">
              <ListItemIcon>
                <PublicIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary={companySiteUrl?.title ?? 'Company site'} />
            </ListItem>
          )}
        </List>
      </Box>
      <HelpMenuItem />
    </div>
  );
};

LeftNav.propTypes = {
  clicked: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  whiteLabel: PropTypes.object,
  user: PropTypes.object,
  businessStep: PropTypes.number,
};

export default compose(withRouter)(LeftNav);
